import React from 'react';
import Checkbox from '@/atoms/checkbox';
import './checkboxList.scss';

type Checkbox = {
  label: string;
  id: string | number;
  onChange: (checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
};

export enum Layout {
  OneColumn,
  TwoColumn,
}

export type Props = {
  checkboxes: Checkbox[];
  layout?: Layout;
};

const layoutClass = {
  [Layout.OneColumn]: 'ds-checkbox-list--layout-onecolumn',
  [Layout.TwoColumn]: 'ds-checkbox-list--layout-twocolumn',
} as const;

export default function CheckboxList({ checkboxes, layout = Layout.OneColumn }: Props) {
  return (
    <div className={`ds-checkbox-list ${layoutClass[layout]}`}>
      {checkboxes.map((checkbox) => (
        <Checkbox
          label={checkbox.label}
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          key={checkbox.id}
          disabled={checkbox.disabled}
        />
      ))}
    </div>
  );
}
