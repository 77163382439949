import React from 'react';

import './error-text.scss';

type Props = {
  children: string | JSX.Element;
};
export default function ErrorText({ children }: Props) {
  return <span className="error-text">{children}</span>;
}
