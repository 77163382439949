import React, { useState } from 'react';
import { VerticalStackedBarChart, TabButton, ChartLegendList } from '@btdt/design-system';
import { getPastMonths, getPastQuarters } from '@/helpers/getPastDates';

type Props = {
  monthly: VerticalStackedBarChart.BarChartDataset[];
  quarterly: VerticalStackedBarChart.BarChartDataset[];
  heading: JSX.Element;
};

const pastTwlveMonths = getPastMonths(12);
const pastEightQuarters = getPastQuarters(8);
export default function ProjectSplitExpanded({ monthly, quarterly, heading }: Props) {
  const [activeTab, setActiveTab] = useState<'monthly' | 'quarterly'>('monthly');

  const monthlyLegends = monthly.map((dataset) => ({
    name: dataset.label,
    legendBox: { backgroundColor: dataset.backgroundColor },
    large: true,
  }));

  const quarterlyLegends = quarterly.map((dataset) => ({
    name: dataset.label,
    legendBox: { backgroundColor: dataset.backgroundColor },
    large: true,
  }));

  return (
    <div className="ds-project-split ds-project-split--large">
      {heading}
      <div className="ds-project-split__big-chart">
        {activeTab === 'monthly' && (
          <VerticalStackedBarChart.VerticalStackedBarChart
            datasets={monthly}
            labels={pastTwlveMonths}
            detailed
            unit="percent"
          />
        )}
        {activeTab === 'quarterly' && (
          <VerticalStackedBarChart.VerticalStackedBarChart
            datasets={quarterly}
            labels={pastEightQuarters}
            detailed
            unit="percent"
          />
        )}
      </div>
      <div className="ds-project-split__legends">
        {activeTab === 'monthly' && <ChartLegendList legends={monthlyLegends} />}
        {activeTab === 'quarterly' && <ChartLegendList legends={quarterlyLegends} />}
      </div>
      <div className="ds-project-split__tabs">
        <span className="ds-project-split__show-by">Show by:</span>
        <TabButton label="Month" onClick={() => setActiveTab('monthly')} selected={activeTab === 'monthly'} />
        <TabButton label="Quarter" onClick={() => setActiveTab('quarterly')} selected={activeTab === 'quarterly'} />
      </div>
    </div>
  );
}
