import 'core-js/es/';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as MockingConfig from '@btdt/design-system/helpers/MockingConfig';

import { RebrandedFooter } from '@btdt/design-system';
import {
  Login,
  LoggedOut,
  ForgotPassword,
  ResetPasswordEmailSuccess,
  ResetPasswordSuccess,
  ChangePasswordSucess,
  ChangePassword,
  ResetPassword,
  WhatsAppOptIn,
  WelcomeExpertAccount,
} from './AccountsPages/PageContent';
import NiceModal from '@ebay/nice-modal-react';

import './AccountsPages/accounts-pages.scss';

import './btdt.scss';
import { frontendMockingConfig } from './config';

const csrfToken = Cookies.get('csrftoken');
if (csrfToken) {
  axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
  axios.defaults.headers.patch['X-CSRFToken'] = csrfToken;
  axios.defaults.headers.delete['X-CSRFToken'] = csrfToken;
}

history.scrollRestoration = 'manual';

document.body.addEventListener('touchstart', () => {
  document.body.classList.add('touch');
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://52a2310b65594857b34ed87cc615bdd7@sentry.io/1312932',
  });
}

function App(): JSX.Element {
  return (
    <MockingConfig.Context.Provider value={frontendMockingConfig}>
      <div className="accounts-pages__wrapper">
        <Router>
          <NiceModal.Provider>
            <Routes>
              <Route path="accounts/login/" element={csrfToken && <Login csrfToken={csrfToken} />} />
              <Route path="accounts/logout/" element={<LoggedOut />} />
              <Route path="django-admin/logout/" element={<LoggedOut />} />
              <Route path="accounts/password_reset/" element={csrfToken && <ForgotPassword csrfToken={csrfToken} />} />
              <Route
                path="accounts/reset/:uidb64/:token/"
                element={csrfToken && <ResetPassword csrfToken={csrfToken} />}
              />
              <Route path="accounts/password_reset/done/" element={<ResetPasswordEmailSuccess />} />
              <Route path="accounts/reset/done" element={<ResetPasswordSuccess />} />
              <Route path="accounts/password_change/done/" element={<ChangePasswordSucess />} />
              <Route path="accounts/password_change/" element={csrfToken && <ChangePassword csrfToken={csrfToken} />} />
              <Route path="accounts/whatsapp/optin/:uidb64/:token/" element={<WhatsAppOptIn />} />
              <Route path="accounts/welcome/:uidb64/:token/" element={<WelcomeExpertAccount />} />
            </Routes>
          </NiceModal.Provider>
        </Router>
      </div>
      <RebrandedFooter linkedinLink="https://www.linkedin.com/company/btdt-global" />
    </MockingConfig.Context.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('accounts-root'));
