import React, { ReactElement, useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipModel, ChartOptions, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DoughnutLegend from '@/atoms/DoughnutLegend';
import DoughnutChartLoading from './DoughnutChartLoading';
import DoughnutChartNoData from './DoughnutChartNoData';
export { DoughnutChartLoading, DoughnutChartNoData };
import { getTooltipLabel } from './utils';
import './doughnutChart.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export type DataPoint = {
  label: string;
  backgroundColor: string;
  data: number;
};

export type Props = {
  dataPoints: DataPoint[];
  dataType?: 'percentage' | 'fraction';
  defaultDataPoint?: DataPoint;
};

function getMaxDataPoint(dataPoints: DataPoint[]) {
  return [...dataPoints].sort((a, b) => (a.data > b.data ? -1 : 1))[0]!;
}

export function DoughnutChart({
  dataPoints,
  dataType = 'percentage',
  defaultDataPoint = getMaxDataPoint(dataPoints),
}: Props): ReactElement {
  const [highlightedLabel, setHighlightedLabel] = useState<string | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'doughnut'>>(null);
  const sum = dataPoints.reduce((prev, current) => prev + current.data, 0);
  let defaultTooltipValue = getTooltipLabel(dataType, defaultDataPoint.data, sum);

  // Create custom tooltip and set legend highlight
  const initialiseChart = (context: { chart: ChartJS; tooltip: TooltipModel<'doughnut'> | null }) => {
    let tooltipEl = wrapperRef.current?.querySelector('.ds-doughnut-chart__tooltip') as HTMLElement;

    // Create tooltip element
    if (!tooltipEl) {
      tooltipEl = document.createElement('span');
      tooltipEl.className = 'ds-doughnut-chart__tooltip';
      wrapperRef.current?.appendChild(tooltipEl);
    }

    // Set tooltip text
    const tooltipModel = context.tooltip;
    const data = tooltipModel?.dataPoints[0]?.raw as number;
    const label = tooltipModel?.dataPoints[0]?.label as string;
    if (!tooltipModel) {
      tooltipEl.innerHTML = defaultTooltipValue;
      // Highlight largest segment
      setHighlightedLabel(defaultDataPoint.label);
    } else {
      const tooltipLabel = getTooltipLabel(dataType, data, sum);
      tooltipEl.innerHTML = tooltipLabel;
      setHighlightedLabel(label);
    }

    // Position the tooltip
    const chartArea = context.chart.chartArea;
    tooltipEl.style.left = `${chartArea.width / 2}px`;
    tooltipEl.style.top = `${chartArea.height / 2}px`;
  };

  useEffect(() => {
    initialiseChart({ chart: chartRef.current as unknown as ChartJS, tooltip: null });
  }, []);

  // Event handler for legends
  const onMouseEnter = (index: number, data: DataPoint) => {
    setHighlightedLabel(data.label);
    chartRef.current?.setActiveElements([
      {
        index,
        datasetIndex: 0,
      },
    ]);

    let tooltipEl = wrapperRef.current?.querySelector('.ds-doughnut-chart__tooltip') as HTMLElement;
    const label = getTooltipLabel(dataType, data.data, sum);
    tooltipEl.innerHTML = label;
  };

  const onMouseLeave = () => {
    setHighlightedLabel(defaultDataPoint.label);
    chartRef.current?.setActiveElements([]);
    let tooltipEl = wrapperRef.current?.querySelector('.ds-doughnut-chart__tooltip') as HTMLElement;
    tooltipEl.innerHTML = defaultTooltipValue;
  };

  const doughnutData: ChartData<'doughnut'> = {
    labels: dataPoints.map((dataPoint) => dataPoint.label),
    datasets: [
      {
        data: dataPoints.map((dataPoint) => dataPoint.data),
        backgroundColor: dataPoints.map((dataPoint) => dataPoint.backgroundColor),
        borderWidth: 1,
        borderAlign: 'inner',
        hoverBorderColor: '#1d1d1b',
        hoverBorderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      tooltip: { enabled: false, external: initialiseChart },
      legend: {
        display: false,
      },
    },
    cutout: '80%',
  };

  return (
    <div className="ds-doughnut-chart">
      <div className="ds-doughnut-chart__wrapper" ref={wrapperRef}>
        <Doughnut data={doughnutData} options={options} ref={chartRef} />
      </div>
      <div className="ds-doughnut-chart__legends">
        {dataPoints.map((dataPoint, index) => (
          <DoughnutLegend
            key={dataPoint.label}
            label={dataPoint.label}
            color={dataPoint.backgroundColor}
            highlight={highlightedLabel === dataPoint.label}
            onMouseEnter={() => onMouseEnter(index, dataPoint)}
            onMouseLeave={onMouseLeave}
            data={
              dataType === 'percentage'
                ? // Round to 1 decimal place
                  `${Math.round((dataPoint.data * 100 * 10) / sum) / 10}%`
                : dataPoint.data.toString()
            }
          />
        ))}
      </div>
    </div>
  );
}
