import React, { ReactElement } from 'react';
import { VerticalStackedBarChart, ChartModal, ChartLegendList } from '@btdt/design-system';
import { getPastMonths } from '@/helpers/getPastDates';
import ProjectSplitExpanded from './ProjectSplitExpanded';
import ProjectSplitLoading from './ProjectSplitLoading';
import ProjectSplitError from './ProjectSplitError';
export { ProjectSplitLoading, ProjectSplitError };
import './projectSplit.scss';

type Props = { headingTag?: 'h2' | 'h3' | 'h4' | 'h5' } & {
  monthly: VerticalStackedBarChart.BarChartDataset[];
  quarterly: VerticalStackedBarChart.BarChartDataset[];
};

const pastFiveMonths = getPastMonths(5);

export function ProjectSplit({ headingTag = 'h2', monthly, quarterly }: Props): ReactElement {
  const HeadingTag = headingTag;
  const heading = <HeadingTag className="ds-project-split__heading">Project split over time</HeadingTag>;

  const pastFiveMonthsData = monthly.map((dataset) => ({ ...dataset, data: dataset.data.slice(-5) }));

  // Some dataset might not have data for the past 5 months, filter them out
  const pastFiveMonthsLegends = pastFiveMonthsData
    .filter((dataset) => dataset.data.some((d) => d > 0))
    .map((dataset) => ({
      name: dataset.label,
      legendBox: { backgroundColor: dataset.backgroundColor },
      large: true,
    }));

  const smallCard = (
    <div className="ds-project-split ds-project-split--small">
      {heading}
      <div className="ds-project-split__small-chart">
        <VerticalStackedBarChart.VerticalStackedBarChart datasets={pastFiveMonthsData} labels={pastFiveMonths} />
      </div>
      <div className="ds-project-split__legends">
        <ChartLegendList legends={pastFiveMonthsLegends} />
      </div>
    </div>
  );

  const largeCard = <ProjectSplitExpanded monthly={monthly} quarterly={quarterly} heading={heading} />;

  return <ChartModal collapsedComponent={smallCard} expandedComponent={largeCard} />;
}
