import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme } from '@btdt/design-system';

export default function ResetPasswordEmailSuccess(): ReactElement {
  const ctas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Login"
      href="/accounts/login/"
      borderRadius={50}
    />
  );
  const content = (
    <>
      <p>
        We've emailed you instructions for setting your password, if an account exists with the email you entered. You
        should receive them shortly.
      </p>
      <p>
        If you don't receive an email, please make sure you've entered the address you registered with, and check your
        spam folder.
      </p>
    </>
  );
  return (
    <div className="accounts-pages__login-block-wrapper">
      <RebrandedLoginBlock
        title="Email Sent"
        ctas={ctas}
        content={content}
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
      />
    </div>
  );
}
