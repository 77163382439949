import React from 'react';
import './staticIcon.scss';
import SettingSVG from '@/static/icons/settings-large.svg';
import InfoSVG from '@/static/icons/info.svg';
import NoWifiSVG from '@/static/icons/nowifi.svg';
import ArrowSVG from '@/static/icons/arrow.svg';
import ErrorSVG from '@/static/icons/error.svg';
import QuestionSVG from '@/static/icons/question.svg';

export enum Icons {
  Info,
  Arrow,
  Error,
  NoWifi,
  Settings,
  Question,
}

const SVGS = {
  [Icons.Info]: <InfoSVG className="ds-static-icon" title="Information" />,
  [Icons.Arrow]: <ArrowSVG className="ds-static-icon" title="Open settings" />,
  [Icons.NoWifi]: <NoWifiSVG className="ds-static-icon" title="Open settings" />,
  [Icons.Settings]: <SettingSVG className="ds-static-icon" title="Open settings" />,
  [Icons.Error]: <ErrorSVG className="ds-static-icon" title="Open settings" />,
  [Icons.Question]:  <QuestionSVG className="ds-static-icon" title="Question" />
} as const;

type Props = {
  Icon: Icons;
};

export default function StaticIcon({ Icon }: Props): JSX.Element {
  return SVGS[Icon];
}
