import React, { ReactElement } from 'react';
import { VerticalStackedBarChart, ChartModal } from '@btdt/design-system';
import { getPastMonths } from '@/helpers/getPastDates';

type Props = {
  headingTag?: 'h2' | 'h3' | 'h4' | 'h5';
};

const pastFiveMonths = getPastMonths(5);

export default function ProjectSplitLoading({ headingTag = 'h2' }: Props): ReactElement {
  const HeadingTag = headingTag;

  const SmallCard = (
    <div className="ds-project-split ds-project-split--loading">
      <HeadingTag className="ds-project-split__heading">Project split over time</HeadingTag>
      <div className="ds-project-split__small-chart">
        <VerticalStackedBarChart.VerticalStackedBarChartLoading labels={pastFiveMonths} />
      </div>
    </div>
  );

  return <ChartModal collapsedComponent={SmallCard} expandedComponent={null} disabled />;
}
