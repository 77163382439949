import { draw } from 'patternomaly';

export const chartColors = {
  peach: ['#FED9C6', '#555A5F', '#1D1D1B', '#FEEDE4', '#E5E5E5', '#F2B291', '#A6A8B0', '#555A5F'],
  teal: ['#0CB8BB', '#E5E5E5'],
};

export const chartPatterns = [
  'cross',
  'plus',
  'dash',
  'dot',
  'line',
  'line-vertical',
  'zigzag',
  'square',
  'box',
  'triangle',
  'diamond',
] as const;

export type ChartPatterns = typeof chartPatterns[number];

export const peachPatterns = [
  ...chartColors.peach,
  ...chartPatterns.map((pattern) => chartColors.peach.map((color) => draw(pattern, color))).flat(),
];
