import React, { ReactElement } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  LinearScale,
  PointElement,
  CategoryScale,
  ChartData,
  ChartOptions,
  Legend,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(Tooltip, LinearScale, CategoryScale, Legend, PointElement, LineElement);

type Props = {
  labels: string[];
  unit?: 'none' | 'percent';
};

export default function LineGraphLoading({ labels, unit = 'none' }: Props): ReactElement {
  const dummyData = Array(labels.length).fill(0);
  dummyData[labels.length - 1] = unit === 'percent' ? 1 : 100;

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        label: 'null',
        data: dummyData,
        borderColor: 'transparent',
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          format:
            unit === 'percent'
              ? {
                  style: 'percent',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }
              : undefined,
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 12,
            lineHeight: 1.4,
          },
          stepSize: unit === 'percent' ? 0.25 : 25,
        },
        grid: {
          borderColor: 'transparent',
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
        ticks: {
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 12,
            lineHeight: 1.4,
          },
        },
        offset: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return <Line data={data} options={options} />;
}
