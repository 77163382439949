import * as React from 'react';
import ModalUnstyled, { ModalUnstyledProps } from '@mui/base/ModalUnstyled';
import './overlay.scss';

type Props = React.PropsWithChildren<
  ModalUnstyledProps & {
    foregroundColor?: React.CSSProperties['color'];
    foregroundLocation?: ForegroundLocation;
    gridTemplateColumnsNone?: boolean;
  }
>;

/**
 * Render an overlay that requires input from the user.
 *
 * Provide children to render something on top of the overlay.
 *
 */
export default function Overlay(props: Props): React.ReactElement {
  const {
    foregroundLocation = ForegroundLocation.CENTER,
    foregroundColor = 'transparent',
    gridTemplateColumnsNone,
    children,
    ...modalProps
  } = props;
  const foregroundClasses = ['btdt-overlay__foreground', foregroundLocationClass(foregroundLocation)].join(' ');

  return (
    <ModalUnstyled
      className={`btdt-overlay ${gridTemplateColumnsNone ? 'grid-columns-none':''}`}
      component="div"
      BackdropComponent={Backdrop}
      container={document.body /* Render the overlay as direct child on document body */}
      {...modalProps}
    >
      <div className={foregroundClasses} style={{ backgroundColor: foregroundColor }}>
        {children}
      </div>
    </ModalUnstyled>
  );
}

export enum ForegroundLocation {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}
const foregroundLocationClass = (
  location: ForegroundLocation
): `btdt-overlay__foreground--${Lowercase<ForegroundLocation>}` => {
  return `btdt-overlay__foreground--${location}`;
};

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return <div className="btdt-overlay__backdrop" ref={ref} {...other}></div>;
});
