import React, { ReactElement, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { DoughnutChartLoading, DoughnutChartNoData } from '../DoughnutChart';
export { DoughnutChartLoading as PieChartLoading, DoughnutChartNoData as PieChartNoData };
import DoughnutLegend from '@/atoms/DoughnutLegend';
import './pieChart.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export type DataPoint = {
  label: string;
  backgroundColor: string;
  data: number;
};

type Props = {
  dataPoints: DataPoint[];
  dataType?: 'percentage' | 'fraction';
};

export function PieChart({ dataPoints, dataType = 'percentage' }: Props): ReactElement {
  const [highlightedLabel, setHighlightedLabel] = useState<string | null>(null);
  const chartRef = useRef<ChartJS>(null);
  const sum = dataPoints.reduce((prev, current) => prev + current.data, 0);

  // Event handler for legends
  const onMouseEnter = (index: number, data: DataPoint) => {
    setHighlightedLabel(data.label);
    chartRef.current?.setActiveElements([
      {
        index,
        datasetIndex: 0,
      },
    ]);

    // Show tooltip
    const tooltip = chartRef.current?.tooltip;
    tooltip?.setActiveElements([{ index, datasetIndex: 0 }], { x: 0, y: 0 });
    chartRef.current?.update();
  };

  const onMouseLeave = () => {
    setHighlightedLabel(null);
    chartRef.current?.setActiveElements([]);

    // Remove tooltip
    const tooltip = chartRef.current?.tooltip;
    tooltip?.setActiveElements([], { x: 0, y: 0 });
    chartRef.current?.update();
  };

  const pieData: ChartData<'pie'> = {
    labels: dataPoints.map((dataPoint) => dataPoint.label),
    datasets: [
      {
        data: dataPoints.map((dataPoint) => dataPoint.data),
        backgroundColor: dataPoints.map((dataPoint) => dataPoint.backgroundColor),
        borderWidth: 0,
        hoverBorderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'pie'> = {
    // Without resize delay animation will not show
    resizeDelay: 100,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return context.label;
          },
          afterLabel: (context) => {
            let afterLabel = '';
            if (dataType === 'percentage') {
              // Round to 1 decimal place
              afterLabel = `${context.parsed} / ${sum} • ${Math.round((context.parsed * 100 * 10) / sum) / 10}%`;
            } else {
              afterLabel = `${context.label}: ${context.parsed}`;
            }
            return afterLabel;
          },
        },
      },
    },
  };

  return (
    <div className="ds-pie-chart">
      <div className="ds-pie-chart__wrapper">
        <Pie data={pieData} options={options} ref={chartRef} />
      </div>
      <div className="ds-pie-chart__legends">
        {dataPoints.map((dataPoint, index) => (
          <DoughnutLegend
            key={dataPoint.label}
            label={dataPoint.label}
            color={dataPoint.backgroundColor}
            highlight={highlightedLabel === dataPoint.label}
            onMouseEnter={() => onMouseEnter(index, dataPoint)}
            onMouseLeave={onMouseLeave}
            data={
              dataType === 'percentage'
                ? // Round to 1 decimal place
                  `${Math.round((dataPoint.data * 100 * 10) / sum) / 10}%`
                : dataPoint.data.toString()
            }
          />
        ))}
      </div>
    </div>
  );
}
