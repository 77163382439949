import { useState, useEffect } from 'react';

function useMobile(mobileWidth = '768px', defaultState = false): boolean {
  const [mobile, setMobile] = useState(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${mobileWidth})`);
    return !mediaQuery.matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${mobileWidth})`);
    setMobile(!mediaQuery.matches);
    const handler = (e: MediaQueryListEvent): void => setMobile(!e.matches);
    mediaQuery.addEventListener('change', handler);
    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, [mobileWidth]);

  return mobile;
}

export { useMobile };
