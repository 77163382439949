import React from 'react';
import './loginBlock.scss';
import StaticIcon, { Icons } from '@/atoms/staticIcon';
import { useMobile } from '@/helpers/mediaHooks';

export type Props = {
  title: string;
  inputs?: JSX.Element | JSX.Element[];
  ctas: JSX.Element | [JSX.Element, JSX.Element];
  content?: JSX.Element | string;
  backUrl?: string;
  image: string;
  mobileImage?: string;
};

export default function LoginBlock({
  title = 'Member Portal',
  ctas,
  inputs,
  content,
  backUrl,
  image,
  mobileImage,
}: Props) {
  const isMobile = useMobile();
  return (
    <div className="rebrand-login-block">
      <div className="rebrand-login-block__content">
        {backUrl && (
          <a className="rebrand-login-block__back-link" href={backUrl}>
            <StaticIcon Icon={Icons.Arrow} />
          </a>
        )}
        <div className="rebrand-login-block__header">
          <h5 className="rebrand-login-block__heading">{title}</h5>
        </div>
        {content && <div className="rebrand-login-block__help-text">{content}</div>}
        <div className="rebrand-login-block__inputs">{inputs}</div>
        <div className="rebrand-login-block__actions">{ctas}</div>
      </div>
      <div
        className="rebrand-login-block__image"
        style={{ backgroundImage: isMobile && mobileImage ? `url(${mobileImage})` : `url(${image})` }}
      />
    </div>
  );
}
