import React, { ReactElement } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  LinearScale,
  PointElement,
  CategoryScale,
  ChartData,
  ChartOptions,
  Legend,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(Tooltip, LinearScale, CategoryScale, Legend, PointElement, LineElement);

import LineGraphLoading from './LineGraphLoading';
export { LineGraphLoading };
import { createPulseCanvas } from './pulse';

type Dataset = {
  label: string;
  data: number[];
  borderColor: string;
  style: 'pulse' | 'dashed';
};

export type Props = {
  labels: string[];
  datasets: Dataset[];
  unit?: 'none' | 'percent';
};

const createPulsePointStyle = (dataLength: number, color: string) => {
  const pointStyle = Array(dataLength).fill('circle');
  pointStyle[dataLength - 1] = createPulseCanvas(color);
  return pointStyle;
};

export function LineGraph({ labels, datasets, unit = 'none' }: Props): ReactElement {
  const data: ChartData<'line'> = {
    labels,
    datasets: datasets.map((d) => {
      switch (d.style) {
        case 'dashed':
          return {
            label: d.label,
            data: d.data,
            borderColor: d.borderColor,
            borderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 0,
            borderDash: [2, 3],
          };
        case 'pulse':
        default:
          return {
            label: d.label,
            data: d.data,
            borderColor: d.borderColor,
            borderWidth: 1,
            pointRadius: 3,
            pointBackgroundColor: 'white',
            pointStyle: createPulsePointStyle(d.data.length, d.borderColor),
          };
      }
    }),
  };

  const options: ChartOptions<'line'> = {
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          format:
            unit === 'percent'
              ? {
                  style: 'percent',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }
              : undefined,
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 12,
            lineHeight: 1.4,
          },
          stepSize: unit === 'percent' ? 0.1 : undefined,
        },
        grid: {
          borderColor: 'transparent',
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
        ticks: {
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 12,
            lineHeight: 1.4,
          },
        },
        offset: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        display: (context) => context.dataIndex === context.dataset.data.length - 1,
        font: {
          family: 'Muli',
          weight: 700,
          size: 12,
        },
        padding: {
          bottom: 0,
        },
        color: (context) => (context.dataset.borderColor ?? '#555A5F') as string,
        formatter: (value) => (unit === 'percent' ? `${Math.round(value * 1000) / 10}%` : value),
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            unit === 'percent'
              ? `${Math.round(context.parsed.y * 1000) / 10}%`
              : context.parsed.y.toString(),
        },
      },
    },
    animations: {
      // We use tension to keep animations looping so pulse won't stop
      // The graph itself does not need tension, hence it's set to a value that's not visible on the graph
      tension: {
        duration: 1000,
        from: 0,
        to: 0.00000001,
        loop: true,
      },
      // Disable y axis loop so the initial line animation won't loop
      y: {
        loop: false,
      },
    },
  };

  return <Line data={data} options={options} plugins={[ChartDataLabels]} />;
}
