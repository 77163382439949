import React, { ReactElement } from 'react';

export default function WelcomeExpertAccount(): ReactElement {
    const url = window.btdt.welcomePageData?.url ?? '';
    return (
        <div className="welcome-expert-page-content__introduction">
            <div className="welcome-expert-page-content__introduction__header">
                <div className="welcome-expert-page-content__introduction__header-content">
                    <h1>Welcome</h1>
                    <div>
                        <p>We are BeenThereDoneThat. You are a World’s Best Thinker.</p>
                        <p>Together we can solve the world’s toughest problems.</p>
                        <p>Welcome to the community!</p>
                    </div>
                </div>
            </div>
            <div className="welcome-expert-page-content__body">
                <div className="welcome-expert-page-content__introduction__inner">
                    <h3>So now that you are part of the gang, here’s a few things that you can look forward to:</h3>
                    <ul>
                        <li>Anonymity:</li>
                        <li>Our engagements remain confidential, we won’t say we work with you and you won’t say you work with us.</li>
                        <li>No clients:</li>
                        <li>We’ll handle the bits you don’t love, so you can focus on the bits you do.</li>
                        <li>Payday:</li>
                        <li>We pay for every engagement, no crowdsourcing, no competition.</li>
                    </ul>
                    <h3>And here’s what we ask of you:</h3>
                    <ul>
                        <li>Your point of view:</li>
                        <li>Behind every brief sits the question ‘if it was your money, what would you do?’</li>
                        <li>Your commitment:</li>
                        <li>There’s never any pressure to say yes to a brief, but if you’re in, it means you’re in - so please give the problem the time it deserves.</li>
                        <li>Your feedback:</li>
                        <li>Our community is a two way ecosystem, so we will give feedback to you and we encourage you to do the same to us.</li>
                        <li></li>
                        <li>Finally, we promise to keep your data safe. The information we collect will only be used by us and for the benefit of creating a better experience for you. If you fancy a read, our privacy policy is <a href="https://www.beentheredonethat.co/legal/privacy-policy" >here.</a></li>
                    </ul>
                    <div className="welcome-expert-page-content__introduction__buttons">
                        <div>
                            <h4 className="welcome-expert-page-content__introduction__buttons-title">Ready? Let’s Get Started...</h4>
                            <a className="rectangle-button " href={url}>
                                <p>Become a Member</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}