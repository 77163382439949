import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './horizontalOverlayedBarChart.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type DataPoint = {
  label: string;
  data: Readonly<[number, number]>;
};

export type Props = {
  dataPoint: DataPoint;
};

export function HorizontalOverlayedBarChartXAxis({ dataPoint }: Props) {
  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        position: 'top',
        ticks: {
          count: 5,
          format: {
            style: 'percent',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
          font: {
            family: 'Muli',
            size: 12,
            weight: 'bold',
          },
          color: '#555A5F',
        },
        grid: {
          borderColor: 'transparent',
          color: 'transparent',
        },
      },
      y: {
        display: false,
      },
    },
  };

  const data: ChartData<'bar'> = {
    datasets: [{ data: [...dataPoint.data], label: dataPoint.label, backgroundColor: 'transparent' }],
  };

  return (
    <div className="ds-horizontal-overlayed-bar-chart">
      <Bar options={options} data={data} height={40} />
    </div>
  );
}
