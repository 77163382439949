import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme, RebrandedInput } from '@btdt/design-system';

type Props = {
  csrfToken: string;
};

export default function ResetPassword({ csrfToken }: Props): ReactElement {
  const inputs = [
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.nonFieldErrors ?? '' }}
    />,
    <RebrandedInput name="new_password1" label="New Password" type="password" required minLength={8} />,
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.newPassword1Errors ?? '' }}
    />,
    <RebrandedInput name="new_password2" label="Confirm New Password" type="password" required minLength={8} />,
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.newPassword2Errors ?? '' }}
    />,
  ];
  const validLinkCtas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Reset Password"
      onClick={() => {}}
      type="submit"
    />
  );
  const invalidLinkCtas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Request a New Reset Link"
      href="/accounts/password_reset/"
      type="submit"
    />
  );
  return window.btdt.accountsPagesData?.resetLinkIsValid === 'True' ? (
    <form method="post" className="accounts-pages__login-block-wrapper">
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      <RebrandedLoginBlock
        title="Reset Password"
        inputs={inputs}
        ctas={validLinkCtas}
        content="Set and confirm your new password below. Your new password should contain at least 8 characters."
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
      />
    </form>
  ) : (
    <div className="accounts-pages__login-block-wrapper">
      <RebrandedLoginBlock
        title="Reset Link Invalid"
        content="The password reset link was invalid, possibly because it has already been used. Please request a new password
        reset."
        ctas={invalidLinkCtas}
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
      />
    </div>
  );
}
