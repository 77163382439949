import React from "react";

export type Config = {
  MOCK_GAPI: boolean;
}

// By default, disable all mocking.
export const defaultConfig: Config = {
  MOCK_GAPI: false,
}

export const Context = React.createContext(defaultConfig);

