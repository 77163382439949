import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button, ButtonTheme } from '@/atoms';
import '../scrollableProjects.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export enum ProjectRowTheme {
  TEAL = 'teal',
  RED = 'red',
  GREY = 'grey',
}

export type Props = {
  name: string;
  client: string;
  numSubmitted: number;
  numOfExperts: number;
  link: string;
  theme?: ProjectRowTheme;
};

export default function ScrollableProjectsRow({
  name,
  client,
  numSubmitted,
  numOfExperts,
  link,
  theme = ProjectRowTheme.GREY,
}: Props) {
  let primaryColour = theme === ProjectRowTheme.RED ? '#BB0C1E' : '#0CB8BB';
  let secondaryColour = theme === ProjectRowTheme.RED ? 'rgb(187, 12, 30, 0.2)' : 'rgb(12, 184, 187, 0.2)';

  const doughnutData: ChartData<'doughnut'> = {
    datasets: [
      {
        data: [numOfExperts - numSubmitted, numSubmitted],
        backgroundColor: [secondaryColour, primaryColour],
        borderWidth: 0,
      },
    ],
  };
  const options: ChartOptions<'doughnut'> = {
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
    cutout: '65%',
    animation: false,
  };

  return (
    <div className={`ds-scrollable-projects__row ds-scrollable-projects__row--${theme}`}>
      <div className="ds-scrollable-projects__row-left">
        <div className="ds-scrollable-projects__doughnut">
          <Doughnut data={doughnutData} options={options} />
        </div>
        <div className="ds-scrollable-projects__names">
          <p className="ds-scrollable-projects__project-name" title={name}>
            {name}
          </p>
          <p className="ds-scrollable-projects__client">{client}</p>
        </div>
      </div>
      <div className="ds-scrollable-projects__row-right">
        <p className="ds-scrollable-projects__submission-count">{`${numSubmitted}/${numOfExperts} experts submitted`}</p>
        <Button
          href={link}
          label="View Project"
          theme={ButtonTheme.BLACK_BORDER}
          borderRadius="1.125rem"
          narrow
        />
      </div>
    </div>
  );
}
