import React, { ReactElement } from 'react';
import { ErrorText, RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme } from '@btdt/design-system';

export default function WhatsAppOptIn(): ReactElement {
  const validToken = Boolean(window.btdt.whatsAppOptInPageData?.validToken);
  const alreadyOptedIn = Boolean(window.btdt.whatsAppOptInPageData?.alreadyOptedIn);
  const isLoggedIn = Boolean(window.btdt.expert);

  const loginCTA = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Login"
      href="/accounts/login/"
      borderRadius={50}
    />
  );
  const dashboardCTA = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Dashboard"
      href="/dashboard"
      borderRadius={50}
    />
  );

  if (validToken) {
    return (
      <div className="accounts-pages__login-block-wrapper">
        <RebrandedLoginBlock
          title="Opt-in to WhatsApp messages"
          image="/static/ds/images/btdt-rebrand-login-noblack.png"
          mobileImage="/static/ds/images/btdt-rebrand-login.png"
          ctas={isLoggedIn ? dashboardCTA : loginCTA}
          content={
            alreadyOptedIn
              ? 'You have already opted-in.'
              : 'Thanks for opting-in to receive messages via WhatsApp. You can return to the app now.'
          }
        />
      </div>
    );
  }
  return (
    <div className="accounts-pages__login-block-wrapper">
      <RebrandedLoginBlock
        title="Invalid Opt-in link"
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
        ctas={isLoggedIn ? dashboardCTA : loginCTA}
        content={
          <ErrorText>
            <p style={{ color: '#FFFFFF' }}>
              Sorry. That opt-in token isn't valid for your account. Please contact{' '}
              <a href="mailto:team@beentheredonethat.co">team@beentheredonethat.co</a>
            </p>
          </ErrorText>
        }
      />
    </div>
  );
}
