import { DateTime } from 'luxon';

// Return an array of months in 'DEV ‘22' format, in chronological order
export const getPastMonths = (numOfMonths: number): string[] => {
  const months = [];
  let now = DateTime.now();

  for (let i = 0; i < numOfMonths; i++) {
    const month = now.monthShort;
    const year = now.year.toString().slice(2);
    const label = `${month} ‘${year}`;
    months.push(label);
    now = now.minus({ month: 1 });
  }

  months.reverse();
  return months;
};

// Return an array of quarters in 'Q1 2022' format, in chronological order
export const getPastQuarters = (numOfQuarters: number): string[] => {
  const quarters = [];
  let now = DateTime.now();

  for (let i = 0; i < numOfQuarters; i++) {
    const quarter = now.quarter;
    const year = now.year.toString().slice(2);
    const label = `Q${quarter} ‘${year}`;
    quarters.push(label);
    now = now.minus({ quarter: 1 });
  }

  quarters.reverse();
  return quarters;
};
