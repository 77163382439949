import React, { useRef, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  AnimationEvent,
  ChartOptions,
  ChartData,
  TooltipModel,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getTextWidth } from '@/helpers/getTextWidth';
import { HorizontalOverlayedBarChartLoading } from './HorizontalOverlayedBarChartLoading';
import { HorizontalOverlayedBarChartXAxis } from './HorizontalOverlayedBarChartXAxis';
export { HorizontalOverlayedBarChartLoading, HorizontalOverlayedBarChartXAxis };
import './horizontalOverlayedBarChart.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// The padding-left of the label on top of a horizontal bar, in px
const labelPadding = 20;
// Paddings for the tooltip
const tooltipPaddingLeft = 8;
const tooltipPaddingTop = -20;

export type DataPoint = {
  label: string;
  // The second data number must be greater than or equal to the first one
  data: Readonly<[number, number]>;
};

export type Category = {
  label: string;
  backgroundColor: string;
};

export type Props = {
  dataPoints: DataPoint[];
  categories: Readonly<[Category, Category]>;
  narrowBar?: boolean;
  showXAxis?: boolean;
};

export function HorizontalOverlayedBarChart({ dataPoints, categories, narrowBar = false, showXAxis = true }: Props) {
  const chartRef = useRef<ChartJS>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Create custom tooltip so it won't be overriden by html labels
  const initialiseChart = (context: { chart: ChartJS; tooltip: TooltipModel<'bar'> | null }) => {
    let tooltipEl = wrapperRef.current?.querySelector('.ds-horizontal-overlayed-bar-chart__tooltip') as HTMLElement;

    // Create tooltip element if there isn't on already
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = 'ds-horizontal-overlayed-bar-chart__tooltip';
      // Hide it initially
      tooltipEl.style.display = 'none';
      wrapperRef.current?.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel) {
      if (tooltipModel.opacity === 0) {
        // Hide if no tooltip
        tooltipEl.style.display = 'none';
      } else {
        tooltipEl.style.display = 'block';
        // Set tooltip text
        const data = `${Math.round((tooltipModel.dataPoints[0]?.raw as number) * 100)}%`;
        const label = tooltipModel.dataPoints[0]?.label as string;
        const category = categories[tooltipModel.dataPoints[0]?.datasetIndex ?? 0];
        tooltipEl.innerHTML = `<p><strong>${label}</strong></p><p><span style="background-color: ${category?.backgroundColor}"></span>${category?.label}: ${data}</p>`;

        // Position the tooltip
        tooltipEl.style.left = `${tooltipModel.caretX + tooltipPaddingLeft}px`;
        tooltipEl.style.top = `${tooltipModel.caretY + tooltipPaddingTop}px`;
      }
    }
  };

  useEffect(() => {
    initialiseChart({ chart: chartRef?.current as unknown as ChartJS, tooltip: null });
  }, []);

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: initialiseChart,
      },
    },
    responsive: true,
    scales: {
      x: {
        ticks: {
          count: 5,
          format: {
            style: 'percent',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
          padding: 7,
          font: {
            family: 'Muli',
            size: 12,
            weight: 'bold',
          },
          color: showXAxis ? '#555A5F' : 'transparent',
        },
        grid: {
          borderColor: '#1D1D1B',
          borderWidth: 1,
          tickLength: 0,
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
      },
    },
    animation: {
      // Position bar labels
      onComplete: (e: AnimationEvent) => {
        const chart: ChartJS & { $rendered?: boolean } = e.chart;

        // Only run on first render
        if (!chart.$rendered) {
          chart.$rendered = true;
          // We only care about the length of the second dataset because it's the longest
          const meta = chart.getDatasetMeta(1);
          meta.data.forEach((bar, index) => {
            // Measuer the width of the label
            const label = chart.scales?.y?.ticks?.[index]?.label;
            if (label && typeof label === 'string') {
              const labelWidth = getTextWidth(label, '600 11px sans-serif');

              const labelEl = document.createElement('span');
              labelEl.textContent = label;
              labelEl.className = 'ds-horizontal-overlayed-bar-chart__label';
              labelEl.style.top = `${bar.y}px`;
              // Display the label on the right of the bar if the label is longer than the bar
              if (labelWidth + labelPadding > bar.width) {
                labelEl.classList.add('ds-horizontal-overlayed-bar-chart__label--black');
                labelEl.style.left = `${bar.x + 4}px`;
              } else {
                // Display the label on top of the bar if the label is shorter than the bar
                labelEl.classList.add('ds-horizontal-overlayed-bar-chart__label--white');
                labelEl.style.left = `${labelPadding}px`;
              }

              wrapperRef.current?.appendChild(labelEl);
            }
          });
        }
      },
    },
  };

  const datasetConfigs = {
    borderRadius: {
      topRight: 4,
      bottomRight: 4,
    },
    categoryPercentage: 0.7,
    barPercentage: 1,
  };

  const data: ChartData<'bar'> = {
    labels: [],
    datasets: categories.map((category) => ({
      ...datasetConfigs,
      label: category.label,
      backgroundColor: category.backgroundColor,
      data: [],
    })),
  };
  dataPoints.forEach((d) => {
    data.labels?.push(d.label);
    data.datasets[0]?.data.push(d.data[0]);
    data.datasets[1]?.data.push(d.data[1]);
  });

  return (
    <div className="ds-horizontal-overlayed-bar-chart" ref={wrapperRef}>
      <Bar options={options} data={data} height={dataPoints.length * (narrowBar ? 20 : 35)} ref={chartRef} />
    </div>
  );
}
