import React, { ReactElement } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  LinearScale,
  CategoryScale,
  BarElement,
  ChartData,
  ChartOptions,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(Tooltip, LinearScale, CategoryScale, BarElement, Legend);
import VerticalStackedBarChartLoading from './VerticalStackedBarChartLoading';
export { VerticalStackedBarChartLoading };

export type BarChartDataset = {
  data: number[];
  backgroundColor: string | CanvasPattern;
  label: string;
};

export type Props = {
  datasets: BarChartDataset[];
  labels: string[];
  detailed?: boolean;
  unit?: 'none' | 'percent';
};

export function VerticalStackedBarChart({ datasets, labels, detailed = false, unit = 'none' }: Props): ReactElement {
  const unitYTicksOptions =
    unit === 'percent'
      ? {
          format: { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 },
        }
      : {};

  const datasetsWithConfigs = datasets.map((dataset) => ({
    ...dataset,
    borderWidth: {
      bottom: 3,
    },
    categoryPercentage: 1,
    barPercentage: detailed ? 0.55 : 0.7,
    borderRadius: {
      topLeft: 3,
      topRight: 3,
      bottomLeft: 6,
      bottomRight: 6,
    },
    borderSkipped: false,
    hoverBorderColor: 'white',
  }));
  const data: ChartData<'bar'> = {
    labels,
    datasets: datasetsWithConfigs,
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
        ticks: {
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 13,
            lineHeight: 1.4,
          },
          padding: 0,
        },
      },
      y: {
        stacked: true,
        display: detailed,
        grid: {
          borderColor: 'transparent',
        },
        ticks: {
          count: 5,
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 12,
            lineHeight: 1.4,
          },
          ...unitYTicksOptions,
        },
        max: unit === 'percent' ? 1 : undefined,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: 'Muli',
        },
        bodyFont: {
          family: 'Muli',
        },
        callbacks: {
          // Spacing between color block and text in label
          label: (context) => `  ${context.dataset.label}` ?? '',
        },
      },
    },
    borderColor: 'transparent',
    // Make the bar chart fill up the entire height of its parent container
    maintainAspectRatio: false,
  };

  return <Bar data={data} options={options} />;
}
