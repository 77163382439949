import React, { ReactElement } from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  LinearScale,
  CategoryScale,
  BarElement,
  ChartData,
  ChartOptions,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(Tooltip, LinearScale, CategoryScale, BarElement, Legend);

type Props = {
  labels: string[];
};

export default function VerticalStackedBarChartLoading({ labels }: Props): ReactElement {
  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        data: Array(labels.length).fill(0),
        backgroundColor: 'white',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
        ticks: {
          font: {
            family: 'Muli',
            weight: 'bold',
            size: 10,
            lineHeight: 1.4,
          },
          padding: 0,
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    // Make the bar chart fill up the entire height of its parent container
    maintainAspectRatio: false,
  };

  return <Bar data={data} options={options} />;
}
