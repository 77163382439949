import React from 'react';
import './spinner.scss';
import { getClasses } from '@/helpers/classes';

export enum SpinnerTheme {
  Pink = 'pink',
  Black = 'black',
}

type Props = {
  theme?: SpinnerTheme;
};

const Spinner = ({ theme = SpinnerTheme.Pink }: Props): JSX.Element => {
  const classRecords = {
    'ds-spinner': true,
    [`ds-spinner--theme-${theme}`]: true,
  };
  const classes = getClasses(classRecords);

  return (
    <svg className={classes} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="18" />
    </svg>
  );
};

export default Spinner;
