import React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { SyncState } from '@/helpers/useSyncState';
import { getClasses } from '@/helpers/classes';
import TextField from '@mui/material/TextField';

import TickSVG from '@/static/icons/circle-tick.svg';
import ErrorSVG from '@/static/icons/exclamation.svg';
import Spinner from '../spinner';
import ArrowDownSvg from '@/static/icons/arrow-down.svg';
import ClearFieldSvg from '@/static/icons/clear-field.svg';
import './autocompleteDropdown.scss';

export type Option = {
  id: number | string;
  label: string;
  inputValue?: string;
};

export type Props = {
  title: string;
  hideTitle?: boolean;
  options: Option[];
  selectedOptions: Option[];
  onChange: (option: Option[]) => void;
  addNew?: (label: string) => void;
  syncState?: SyncState;
};

const filter = createFilterOptions<Option>();

export default function AutocompleteDropdown({
  title,
  hideTitle = false,
  options,
  onChange,
  selectedOptions,
  addNew,
  syncState = SyncState.NONE,
}: Props) {
  const classes = getClasses({
    'ds-autocomplete-dropdown': true,
    'ds-autocomplete-dropdown--selected': selectedOptions.length > 0,
    'ds-autocomplete-dropdown--no-title': hideTitle,
    'ds-autocomplete-dropdown--synced': syncState === SyncState.SYNCED,
    'ds-autocomplete-dropdown--sync-failed': syncState === SyncState.ERROR,
  });

  return (
    <div className={classes}>
      <div className="ds-autocomplete-dropdown__wrapper">
        <Autocomplete
          multiple
          options={options}
          value={selectedOptions}
          onChange={(event, newValue) => {
            if (addNew && event.currentTarget.id === 'add-new-option') {
              const inputValue = newValue.find((value) => value.id === 'add-new-option')?.inputValue;
              if (inputValue) addNew(inputValue);
            } else {
              onChange(newValue);
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={title} />}
          popupIcon={<ArrowDownSvg />}
          clearIcon={syncState === SyncState.NONE ? <ClearFieldSvg /> : null}
          disablePortal={true}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (!addNew) return filtered;
            const { inputValue } = params;
            const valueExists = options.some((option) => inputValue === option.label);
            if (inputValue !== '' && !valueExists) {
              filtered.unshift({
                id: 'add-new-option',
                label: 'Add New +',
                inputValue,
              });
            }
            return filtered;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id} id={typeof option.id === 'number' ? option.id.toString() : option.id}>
              {option.label}
            </li>
          )}
        />
        {syncState === SyncState.ERROR && (
          <div className="ds-autocomplete-dropdown__sync-state">
            <ErrorSVG />
          </div>
        )}
        {syncState === SyncState.SYNCED && (
          <div className="ds-autocomplete-dropdown__sync-state">
            <TickSVG />
          </div>
        )}
        {syncState === SyncState.SYNCING && (
          <div className="ds-autocomplete-dropdown__sync-state ds-autocomplete-dropdown__sync-state--syncing">
            <Spinner />
          </div>
        )}
        {syncState === SyncState.ERROR && (
          <span className="ds-autocomplete-dropdown__error ds-autocomplete-dropdown__error--sync">Failed to sync</span>
        )}
      </div>
    </div>
  );
}
