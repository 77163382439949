import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function HorizontalOverlayedBarChartLoading() {
  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        ticks: {
          count: 5,
          format: {
            style: 'percent',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
          padding: 7,
          font: {
            family: 'Muli',
            size: 12,
            weight: 'bold',
          },
          color: '#555A5F',
        },
        grid: {
          borderColor: '#1D1D1B',
          borderWidth: 1,
          tickLength: 0,
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          borderColor: '#1D1D1B',
          borderWidth: 1,
        },
      },
    },
    animation: {
      duration: 0,
    },
  };

  const data: ChartData<'bar'> = {
    labels: Array(7).fill(undefined),
    datasets: [
      {
        label: 'loading',
        data: Array(7).fill(0),
      },
    ],
  };

  return (
    <div className="ds-horizontal-overlayed-bar-chart">
      <Bar options={options} data={data} height={220} />
    </div>
  );
}
