import React from 'react';
import LinkedInSVG from '@/static/icons/rebrand-linkedin-footer.svg';
import Logo from '@/static/icons/rebrand-logo.svg';
import './footer.scss';

type Props = {
  linkedinLink: string;
};

export default function Footer({ linkedinLink }: Props) {
  return (
    <footer className="rebrand-footer">
      <div className="rebrand-footer__wrapper">
        <div className="rebrand-footer__socials">
          <a href={linkedinLink}>
            <LinkedInSVG />
          </a>
        </div>
        <div className="rebrand-footer__logo">
          <Logo />
        </div>
        <div className="rebrand-footer__nav">
          <ul>
            <li>
              <a href="https://www.beentheredonethat.co/how-we-work">How We Work</a>
            </li>
            <li>
              <a href="https://www.beentheredonethat.co/content-hub">Content Hub</a>
            </li>
            <li>
              <a href="https://www.beentheredonethat.co/contact">Contact</a>
            </li>
            <li>
              <a href="https://app.beentheredonethat.co/accounts/login">Login</a>
            </li>
          </ul>
        </div>
        <div className="rebrand-footer__addresses">
          <div className="rebrand-footer__address">
            <h4>US Address</h4>
            <span>
              BTDT Global Inc
              <br />
              NeueHouse Madison Square
              <br />
              110 East 25th Street
              <br />
              New York
              <br />
              10010
            </span>
          </div>
          <div className="rebrand-footer__address">
            <h4>UK Address</h4>
            <span>
              Been There Done That Global Limited
              <br />
              1st Floor Unit 1C
              <br />
              Zetland House
              <br />
              5-25 Scrutton Street
              <br />
              London EC2A 4HJ
            </span>
          </div>
        </div>
        <div className="rebrand-footer__copyright">
          <p>Copyright 2024</p>
          <p>BeenThereDoneThat Ltd.</p>
        </div>
        <div className="rebrand-footer__privacy">
          <a href="https://www.beentheredonethat.co/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}
