import * as Leaflet from 'leaflet';
import * as GeoJSON from 'geojson';
import { CountriesGeoJSON } from './data/schema';
import { BTDTData } from './types';

const FALLBACK_BOUNDS = new Leaflet.LatLngBounds(new Leaflet.LatLng(-90, -180), new Leaflet.LatLng(90, 180));

export function createBoundingBox(
  collection: GeoJSON.FeatureCollection,
  fallback = FALLBACK_BOUNDS
): Leaflet.LatLngBoundsExpression {
  if (collection.bbox) {
    const [minx, miny, maxx, maxy] = collection.bbox;
    const minLatLng = new Leaflet.LatLng(miny, minx);
    const maxLatLng = new Leaflet.LatLng(maxy, maxx);
    return new Leaflet.LatLngBounds(minLatLng, maxLatLng);
  }

  return fallback;
}
