import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme, RebrandedInput } from '@btdt/design-system';

type Props = {
  csrfToken: string;
};

export default function ChangePassword({ csrfToken }: Props): ReactElement {
  const inputs = [
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.nonFieldErrors ?? '' }}
    />,
    <RebrandedInput name="old_password" label="Current Password" type="password" required />,
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.oldPasswordErrors ?? '' }}
    />,
    <RebrandedInput name="new_password1" label="New Password" type="password" required minLength={8} />,
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.newPassword1Errors ?? '' }}
    />,
    <RebrandedInput name="new_password2" label="Confirm New Password" type="password" required minLength={8} />,
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.newPassword2Errors ?? '' }}
    />,
  ];
  const ctas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Change Password"
      onClick={() => {}}
      type="submit"
    />
  );
  return (
    <form method="post" className="accounts-pages__login-block-wrapper">
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      <RebrandedLoginBlock
        title="Change Password"
        inputs={inputs}
        ctas={ctas}
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
        content="Change and confirm your new password below. Your new password should contain at least 8 characters."
      />
    </form>
  );
}
