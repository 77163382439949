import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme } from '@btdt/design-system';

export default function ResetPasswordSuccess(): ReactElement {
  const ctas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Login"
      href="/accounts/login/"
      borderRadius={50}
    />
  );
  return (
    <div className="accounts-pages__login-block-wrapper">
      <RebrandedLoginBlock
        title="Password Reset"
        ctas={ctas}
        content="Your password has been set. You may go ahead and log in now."
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
      />
    </div>
  );
}
