import React, { useEffect, useRef, useState } from 'react';
import InputUnstyled from '@mui/base/InputUnstyled';
import ClearFielrebrandVG from '@/static/icons/clear-field.svg';
import { SyncState } from '@/helpers/useSyncState';
import { getClasses } from '@/helpers/classes';
import TickSVG from '@/static/icons/circle-tick.svg';
import ErrorSVG from '@/static/icons/exclamation.svg';
import Spinner from '@/atoms/spinner';
import './textInput.scss';
import Query from './query';

export type Props = {
  value?: string;
  onChange?: (value: string) => void;
  error?: { isError: boolean; errorText?: string };
  disabled?: boolean;
  label?: string;
  onClear?: () => void; // What do do when the 'clear' x button is pressed.
  multiline?: boolean;
  required?: boolean;
  type?: 'text' | 'password' | 'email';
  name?: string;
  minLength?: number;
  maxLength?: number;
  syncState?: SyncState;
};

export default function TextInput({
  label,
  disabled,
  error,
  onClear,
  onChange,
  value,
  multiline,
  required,
  type,
  name,
  minLength,
  maxLength,
  syncState = SyncState.NONE,
}: Props) {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  // We store the cursor information to prevent this being lost when controlled component changes.
  // This most often happens with components using react query to get data.
  const [cursor, setCursor] = useState<{ start: number | null; end: number | null }>({ start: null, end: null });

  const classes = getClasses({
    'rebrand-input': true,
    'rebrand-input--error': Boolean(error?.isError),
    'rebrand-input--synced': syncState === SyncState.SYNCED,
    'rebrand-input--sync-failed': syncState === SyncState.ERROR,
    'rebrand-input--disabled': Boolean(disabled),
  });

  return (
    <div className={classes}>
      {value && <label className="rebrand-input__label">{label}</label>}
      <div className="rebrand-input__wrapper">
        <InputUnstyled
          className="rebrand-input__input"
          value={value}
          placeholder={label}
          multiline={multiline}
          disabled={disabled}
          required={required}
          type={type}
          name={name}
          onChange={
            onChange
              ? (e) => {
                  setCursor({ start: e.currentTarget.selectionStart, end: e.currentTarget.selectionEnd });
                  onChange(e.currentTarget.value);
                }
              : undefined
          }
          componentsProps={{
            input: {
              minLength,
              maxLength,
              // Padding on right to stop text running over the 'clear'/status icons.
              style: { paddingRight: `calc(2.0rem + ${scrollbarWidth}px)` },
              ref: (el) => {
                if (
                  el instanceof HTMLTextAreaElement ||
                  (el instanceof HTMLInputElement && ['text', 'search', 'url', 'tel', 'password'].includes(el.type))
                ) {
                  el.setSelectionRange(cursor.start, cursor.end);
                }
                if (el) {
                  setScrollbarWidth(el.offsetWidth - el.clientWidth);
                } else {
                  setScrollbarWidth(0);
                }
              },
            },
          }}
        />
        {onClear && value && syncState === SyncState.NONE && (
          <button
            className="rebrand-input__clear"
            onClick={onClear}
            style={{ right: `calc(0.75rem + ${scrollbarWidth}px)` }}
          >
            <ClearFielrebrandVG />
          </button>
        )}

        {syncState === SyncState.ERROR && (
          <div className="rebrand-input__sync-state" style={{ right: `calc(0.75rem + ${scrollbarWidth}px)` }}>
            <ErrorSVG />
          </div>
        )}
        {syncState === SyncState.SYNCED && (
          <div className="rebrand-input__sync-state" style={{ right: `calc(0.75rem + ${scrollbarWidth}px)` }}>
            <TickSVG />
          </div>
        )}
        {syncState === SyncState.SYNCING && (
          <div
            className="rebrand-input__sync-state rebrand-input__sync-state--syncing"
            style={{ right: `calc(0.75rem + ${scrollbarWidth}px)` }}
          >
            <Spinner />
          </div>
        )}
      </div>
      {error?.isError && <span className="rebrand-input__error">{error.errorText}</span>}
      {syncState === SyncState.ERROR && <span className="rebrand-input__error rebrand-input__error--sync">Failed to sync</span>}
    </div>
  );
}

export { TextInput, Query };
