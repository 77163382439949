import * as MockingConfig from '@btdt/design-system/helpers/MockingConfig';

/**
 * Mocking config for frontend.
 *
 * The frontend should not mock anything (unless you have really good reason)
 * The corresponding design system config (See  design-system/.storybook/preview.tsx ) does mock some things.
 *
 */
export const frontendMockingConfig: MockingConfig.Config = {
  MOCK_GAPI: false
}
