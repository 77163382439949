import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme, RebrandedInput } from '@btdt/design-system';

type Props = {
  csrfToken: string;
};

export default function Login({ csrfToken }: Props): ReactElement {
  const inputs = [
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.nonFieldErrors ?? '' }}
    />,
    <RebrandedInput name="username" label="Email" type="email" required />,
    <RebrandedInput name="password" label="Password" type="password" required />,
  ];
  const ctas = [
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Login"
      onClick={() => {}}
      type="submit"
    />,
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_HOLLOW}
      fullWidth
      label="Forgot Password"
      href="/accounts/password_reset/"
      borderRadius={50}
    />,
  ] as [JSX.Element, JSX.Element];
  return (
    <form method="post" className="accounts-pages__login-block-wrapper">
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      <RebrandedLoginBlock
        title="Member Portal"
        inputs={inputs}
        ctas={ctas}
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
      />
    </form>
  );
}
