import React, { ReactElement } from 'react';
import { RebrandedLoginBlock, RebrandedButton, RebrandedButtonTheme, RebrandedInput } from '@btdt/design-system';

type Props = {
  csrfToken: string;
};

export default function ForgotPassword({ csrfToken }: Props): ReactElement {
  const Rebrandedinputs = [
    <div
      className="accounts-pages__error"
      dangerouslySetInnerHTML={{ __html: window.btdt.accountsPagesData?.nonFieldErrors ?? '' }}
    />,
    <RebrandedInput name="email" label="Email" type="email" required />,
  ];
  const ctas = (
    <RebrandedButton
      theme={RebrandedButtonTheme.TEAL_FILLED}
      fullWidth
      label="Submit Email"
      onClick={() => {}}
      type="submit"
    />
  );
  return (
    <form method="post" className="accounts-pages__login-block-wrapper">
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      <RebrandedLoginBlock
        title="Forgot Password"
        inputs={Rebrandedinputs}
        ctas={ctas}
        backUrl="/accounts/login/"
        image="/static/ds/images/btdt-rebrand-login-noblack.png"
        mobileImage="/static/ds/images/btdt-rebrand-login.png"
        content="Enter your email below, and if it is associated with a BTDT member account, you will get a link to reset your password."
      />
    </form>
  );
}
